<template>
  <div class="homepage">
    <homepage-filter-modal
      :is-open.sync="isModalFilterOpen"
      @action-apply-filter="applyFilter"
    />

    <bg-text class="mb-32" tag="h3" size="heading-3">Daftar Properti</bg-text>

    <div class="homepage-filter">
      <div class="homepage-filter__search">
        <property-search-bar
          v-model="search"
          url="/singgahsini/api/property/list-properties-suggestion"
          :placeholder="'Cari berdasarkan ID, Nama Properti'"
        />
      </div>

      <div class="homepage-filter__actions">
        <bg-button size="lg" block @click="openFilterModal">
          <div class="flex align-center justify-center">
            <bg-badge-counter
              v-if="totalFilter"
              :value="totalFilter"
              variant="black"
            />
            <bg-icon v-else name="filter" size="sm" />
            <span class="ml-8">Filter</span>
          </div>
        </bg-button>
        <bg-button
          size="lg"
          :disabled="!totalFilter && !xSearch"
          block
          @click="resetFilter"
        >
          Reset
        </bg-button>
        <bg-button size="lg" variant="primary" block @click="handleSearch">
          Cari
        </bg-button>
      </div>
    </div>

    <homepage-table
      :list="propertyList"
      :loading-list="loadingPropertyList"
      @emit-change-pagination="applyFilter"
    />
  </div>
</template>

<script>
import homepageAPI from '@admin_endpoints/homepage';
import HomepageTable from '@admin_organisms/HomepageTable';
import debounce from 'Utils/debounce';
import PropertySearchBar from '@admin_molecules/PropertySearchBar';

import { BgText, BgButton, BgIcon, BgBadgeCounter } from 'bangul-vue';

import { mapGetters, mapMutations, mapState } from 'vuex';

const DEBOUNCE_SEARCH_TIME = 400;

export default {
  name: 'Homepage',

  components: {
    BgText,
    BgButton,
    BgIcon,
    BgBadgeCounter,
    HomepageTable,
    PropertySearchBar,
    HomepageFilterModal: () => import('@admin_molecules/HomepageFilterModal'),
  },

  data() {
    return {
      loadingPropertyList: true,
      propertyList: [],
      isModalFilterOpen: false,
    };
  },

  computed: {
    ...mapGetters('homepage', ['totalFilter']),
    ...mapState({
      xPagination: ({ homepage }) => homepage.pagination,
      xFilters: ({ homepage }) => homepage.filters,
      xSearch: ({ homepage }) => homepage.search,
    }),

    search: {
      get() {
        return this.xSearch;
      },
      set: debounce(function (value) {
        this.setSearch(value);
      }, DEBOUNCE_SEARCH_TIME),
    },
  },

  created() {
    this.applyFilter();
  },

  beforeDestroy() {
    this.resetStates();
  },

  methods: {
    ...mapMutations('homepage', [
      'setFilter',
      'setSearch',
      'setPagination',
      'resetStates',
    ]),

    async getHomepagePropertyList(params) {
      const result = { isError: true, data: {} };
      try {
        const { data } = await homepageAPI.getHomepagePropertyList(params);
        result.data = data;
        result.isError = false;
      } catch (error) {
        console.error(error);
        result.data = error;
      }
      return result;
    },

    async processingPropertyList(params = {}) {
      this.loadingPropertyList = true;

      const { isError, data } = await this.getHomepagePropertyList(params);

      if (!isError) {
        this.propertyList = data.data.map(item => ({
          ...item,
        }));
        this.setPagination(data.pagination);
      }

      this.loadingPropertyList = false;
    },

    openFilterModal() {
      this.isModalFilterOpen = true;
    },

    applyFilter() {
      const { offset } = this.xPagination;
      const params = {
        offset,
        ...this.xFilters,
      };

      if (this.search) params.q = this.search;

      this.processingPropertyList(params);
    },

    handleApplyFilter: debounce(function () {
      this.applyFilter();
    }, DEBOUNCE_SEARCH_TIME),

    handleSearch: debounce(function () {
      this.applyFilter();
    }, DEBOUNCE_SEARCH_TIME),

    resetFilter() {
      this.resetStates();
      this.applyFilter();
    },
  },
};
</script>

<style lang="scss" scoped src="./Homepage.scss"></style>
