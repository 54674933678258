<template>
  <div class="organism-homepage-table">
    <txt v-if="list.length" size="body-2" class="total-data">
      Ditemukan {{ this.xPagination.total_items }} properti
    </txt>

    <div class="table-container">
      <table>
        <thead>
          <tr>
            <th><txt size="title-5">ID</txt></th>
            <th><txt size="title-5">Nama Properti</txt></th>
            <th><txt size="title-5">Kota</txt></th>
            <th><txt size="title-5">Produk</txt></th>
            <th><txt size="title-5">BSE</txt></th>
            <th><txt size="title-5">BD</txt></th>
            <th><txt size="title-5">AS</txt></th>
            <th><txt size="title-5">Hospitality</txt></th>
            <th><txt size="title-5">Awal Kontrak</txt></th>
            <th><txt size="title-5">Akhir Kontrak</txt></th>
            <th><txt size="title-5">Action</txt></th>
          </tr>
        </thead>

        <tbody>
          <template v-if="loadingList">
            <tr v-for="i in 3" :key="i">
              <td v-for="i in 11" :key="i">
                <skeleton :height="20" width="100%" />
              </td>
            </tr>
          </template>

          <template v-else>
            <tr
              v-for="(property, index) in list"
              :key="`homepage-property-list-${index}`"
            >
              <td>
                {{ property.id }}
              </td>
              <td>
                {{ property.name }}
              </td>
              <td>
                {{ property.area_city }}
              </td>
              <td>
                {{ property.property_type }}
              </td>
              <td>
                {{ property.bse }}
              </td>
              <td>
                {{ property.agent }}
              </td>
              <td>
                {{ property.account_support }}
              </td>
              <td>
                {{ property.supervisor }}
              </td>
              <td>
                {{ property.contract_start | dateFormatter }}
                <div
                  class="mt-8"
                  v-if="
                    property.probation_end_in_days <= 30 &&
                    property.probation_end_in_days
                  "
                >
                  <bg-label color="red">
                    Probation -
                    {{ property.probation_end_in_days | durationText }}
                  </bg-label>
                </div>
              </td>
              <td>
                {{ property.contract_end | dateFormatter }}
                <div
                  class="mt-8"
                  v-if="remainingDays(property.contract_end) <= 60"
                >
                  <bg-label
                    :color="
                      remainingDays(property.contract_end) < 0 ? 'grey' : 'red'
                    "
                    >{{
                      remainingDays(property.contract_end) | durationText
                    }}</bg-label
                  >
                </div>
              </td>
              <td>
                <table-action-menu
                  v-if="accessPropertyDetail || accessRoomAllotment"
                >
                  <list-item
                    v-if="accessPropertyDetail"
                    clickable
                    @click="handleRedirectToPropertyDetail(property.id)"
                  >
                    Lihat Detail
                  </list-item>
                  <list-item
                    v-if="accessRoomAllotment && !property.is_expired"
                    clickable
                    @click="handleRedirectToRoomAvailable(property.id)"
                  >
                    Ketersediaan Kamar
                  </list-item>
                </table-action-menu>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <footer v-if="list.length">
      <template v-if="totalPagination > 1">
        <div v-if="loadingList" class="ta-c">
          <skeleton :width="'175px'" :height="24" />
        </div>
        <pagination
          v-else
          :value="activePagination"
          :pageTotal="totalPagination"
          @click="onPageChanged"
        />
      </template>

      <button-icon
        v-if="accessDownloadCsv"
        class="download-button"
        icon="download"
        variant="primary"
        :size="'lg'"
        :loading="downloadCsvLoading"
        @click="handleDownloadCsv"
        >Unduh CSV</button-icon
      >
    </footer>

    <div class="no-data" v-if="!list.length && !loadingList">
      <txt size="title-2">Data Tidak Ditemukan</txt>
      <txt size="body-2"
        >Data tidak ditemukan di filter atau kata kunci yang Anda gunakan.</txt
      >
    </div>

    <div class="alert-download" :class="{ show: showDownloadAlert }">
      <alert
        title="Unduh dalam Proses"
        description="File dalam proses diunduh. Apabila tidak berhasil, silakan coba unduh file kembali."
        :closable="false"
      />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import homepageAPI from '@admin_endpoints/homepage';

import { mapGetters, mapMutations, mapState } from 'vuex';
import TableActionMenu from '@admin_molecules/TableActionMenu';

import {
  BgText,
  BgPagination,
  BgListItem,
  BgSkeleton,
  BgButtonIcon,
  BgAlert,
  BgLabel,
} from 'bangul-vue';

dayjs.extend(duration);

export default {
  name: 'HomepageTable',

  components: {
    txt: BgText,
    pagination: BgPagination,
    listItem: BgListItem,
    skeleton: BgSkeleton,
    buttonIcon: BgButtonIcon,
    alert: BgAlert,
    BgLabel,
    TableActionMenu,
  },

  filters: {
    dateFormatter(value) {
      if (value) {
        return dayjs(value).format('DD/MM/YYYY');
      }
      return '-';
    },

    durationText(value) {
      if (value < 0) {
        return 'Expired';
      } else if (value === 0) {
        return 'Hari ini';
      } else if (value > 0 && value <= 60) {
        return `${value} hari lagi`;
      }
    },
  },

  props: {
    list: {
      type: Array,
      required: true,
    },
    loadingList: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      downloadCsvLoading: false,
      showDownloadAlert: false,
    };
  },

  computed: {
    ...mapGetters('homepage', ['totalPagination', 'activePagination']),
    ...mapState({
      xPagination: ({ homepage }) => homepage.pagination,
      xFilters: ({ homepage }) => homepage.filters,
      xSearch: ({ homepage }) => homepage.search,
      accessPropertyDetail() {
        return this.$store.getters.xCheckUserPermission('property-detail');
      },
      accessRoomAllotment() {
        return this.$store.getters.xCheckUserPermission('room-allotment');
      },
      accessDownloadCsv() {
        return this.$store.getters.xCheckUserPermission('download-csv');
      },
    }),
  },

  methods: {
    ...mapMutations('homepage', ['changePagination']),

    async getHomepagePropertyListDownload(params) {
      let result = {};
      try {
        result = await homepageAPI.getCsv(params);
      } catch (error) {
        console.error(error);
      }
      return Promise.resolve(result);
    },

    onPageChanged(page) {
      this.changePagination(page);
      this.$emit('emit-change-pagination');
    },

    handleRedirectToRoomAvailable(propertyId) {
      this.$router.push(
        `/room-allotment/calendar-view?property_id=${propertyId}`
      );
    },

    handleRedirectToPropertyDetail(propertyId) {
      this.$router.push({
        name: 'property-detail.overview',
        params: { propertyId },
      });
    },

    async handleDownloadCsv() {
      this.downloadCsvLoading = true;

      const params = { ...this.xFilters };
      if (this.xSearch) params.q = this.search;

      // Window.open(homepageAPI.getCsv(params), '_blank');

      const response = await this.getHomepagePropertyListDownload(params);
      if (response.status) {
        this.showDownloadAlert = true;

        const today = dayjs().format('YYYYMMDDHHmmss');

        const blob = new Blob([response.data], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `PMS-SinggahSini-daftar-properti-${today}.csv`;
        link.click();
      } else {
        this.$toast.show('Gagal mengunduh csv', { duration: 3 });
      }

      setTimeout(() => {
        this.showDownloadAlert = false;
        this.downloadCsvLoading = false;
      }, '3000');
    },

    remainingDays(value) {
      if (value) {
        const valueDate = dayjs(value);
        const today = dayjs();
        const duration = dayjs.duration(valueDate.diff(today)).asDays();
        return Math.ceil(duration);
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./HomepageTable.scss"></style>
